@use "./../../../../styles/variables/colors.scss" as colors;

.Box {
  display: flex;
  flex-direction: column;
  padding: 1.6rem 1.6rem;
  margin: 0.4rem 0 0.4rem 0;

  background-color: colors.$white;
  border-radius: 0.24rem;
  box-shadow: 2px 2px 4px 0px darken(colors.$light-gray, 10%);

  &InnerHeader {
    margin: 1rem 1rem 1rem 0;
    font-size: 1.6rem;
    font-weight: 400;
    color: darken(colors.$dark-gray, 10%);
  }

  &Header {
    margin: 0 0 1.5rem 0;
  }
}

.SponsorBox {
  @extend .Box;

  margin: 0.4rem 0 4rem 0;

  > :nth-child(n) {
    &:not(:first-child) {
      margin-top: 2rem;
    }
    font-size: 1.4rem;
    color: colors.$dark-gray;
  }
}
