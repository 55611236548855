@use "./../../../styles/variables/colors.scss" as colors;

.EventBox {
  display: flex;
  flex-direction: column;
  padding: 1.6rem 1.6rem;
  margin: 0.4rem 0 0.4rem 0;

  background-color: colors.$white;
  border-radius: 0.24rem;
  box-shadow: 2px 2px 4px 0px darken(colors.$light-gray, 10%);


  &:hover {
    scale: 1.01;
    box-shadow: 4px 4px 8px 0px darken(colors.$light-gray, 10%);
    cursor: pointer;
  }

  > :nth-child(1) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    font-size: 1.4rem;
    color: colors.$dark-gray;
  }

  > :nth-child(2) {
    font-size: 1.6rem;
    font-weight: bold;
  }

}
