@use "./../../../../../styles/variables/colors.scss" as colors;

.Modal {
  &Base {
    position: absolute;
    left: 0;
    top: 0;
    width: 99vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.5);
    z-index: 100;
  }

  background-color: colors.$white;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 50%;
  max-height: 90%;
  border-radius: 4px;
  padding: 2rem;
  overflow-y: auto;

    &Header {
        font-size: 2.4rem;
        font-weight: 600;
        margin-bottom: 2rem;
    }
}
