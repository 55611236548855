@use './variables/colors' as colors;

.container {
  display: flex;
  width: 100%;
  max-width: 100vw;
  background-color: colors.$light-gray;
  height: 100%;
  min-height: 100vh;

  > div:nth-child(2) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    width: 100%;
  }
}

.pageHeader {
  display: flex;
  margin: 8rem 0 6rem 0;

  > span {
    margin-left: 2rem;
    :last-child {
      font-size: 2.4rem;
      font-weight: 400;
      margin-top: 0.54rem;
      color: colors.$dark-gray;
    }
  }
}

.w-100 {
  width: 100%;
}

.margin-10 {
  margin: 10px;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mr-2 {
  margin-right: 2rem;
}

.pl-1 {
  padding-left: 1rem;
}

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}
